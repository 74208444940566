import { Directive, Input, OnDestroy } from "@angular/core";
import { ActivatedRoute, Params, Router } from '@angular/router';
import { debounceTime, Subscription } from 'rxjs';

import { Company } from "../models/company.model";

@Directive()
export class TableFilters implements OnDestroy {

  @Input() public company: Company;
  @Input() public paginated: boolean = true;
  @Input() public filters: Params;

  /** Callback function to be triggered when filters change */
  public onFiltersChange?: () => void;
  public subscriptions: Subscription[] = [];
  private alreadySet: boolean = false;

  /** @ignore */
  constructor(
    public route: ActivatedRoute,
    public router: Router
  ) { }

  /**
   * Sets up the filter system by subscribing to route query parameters.
   * It merges existing filters with the new query parameters and triggers the `onFiltersChange` callback if defined.
   */
  public setupFilters(): void {
    if (this.alreadySet) return;
    this.alreadySet = true;

    this.subscriptions.push(this.route.queryParams.pipe(
      debounceTime(150)
    ).subscribe((queryParams: Params) => {
      this.filters = { ...this.filters, ...queryParams };
      if (this.onFiltersChange) this.onFiltersChange();
    }));
  }

  /**
   * Updates the specified filter and triggers a navigation to refresh the URL with the updated filters.
   *
   * @param { { key: string, value: any } } event - The event object containing the filter key and its new value.
   */
  public changeFilter(event: { key: string, value: any }): void {
    // Se utiliza para resetear el paginado cuando se aplica un filtro
    this.filters["page"] = this.paginated ? 1 : undefined;
    this.filters[event.key] = event.value;

    this.navigate();
  }

  /**
   * Navigates to the current route with updated query parameters reflecting the current filters.
   */
  private navigate(): void {
    this.router.navigate([], {
      queryParams: this.filters,
      relativeTo: this.route
    });
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
